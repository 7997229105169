import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { populateProjectData } from './projects';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// const COLOR_LIST = ['#7f00ff', '#ff00ff', '#0000ff', '#007fff', '#00ffff'];
const COLOR_LIST = ['#7f00ff', '#ff00ff', '#0000ff', '#007fff', '#00ffff'];
// const COLOR_LIST = ['#474554', '#4e5a6e', '#517185', '#548996'];
// const COLOR_LIST = ['#8685ef', '#ef85b9', '#eeef85', '#85efbb'];
// const COLOR_LIST = ['#8685ef', '#ef8685', '#85ef86'];
// const COLOR_LIST = ['#8685ef', '#858fef', '#9185ef'];
// const COLOR_LIST = ['#8685ef', '#272645', '#56569a'];
// const COLOR_LIST = ['#8685ef', '#6467cd', '#414aab', '#152f8b', '#00176c'];
// const COLOR_LIST = ['#9781ea', '#ea819f', '#d4ea81', '#81eacb'];
// const COLOR_LIST = ['#8685ef', '#e67ddb', '#ff83b1', '#ffa184', '#ffcc67', '#f9f871'];

let isSmallScreen = window.matchMedia('(max-width: 768px)').matches;
const projectInfo = document.querySelector('.project-info');

// Project description animation

const targetList = document.querySelectorAll('[data-js="reveal"]');

const getArrayRandomValue = (array) =>
  array[Math.floor(Math.random() * array.length)];

export function triggerProjectAnimation() {
  // Use this function outside the trigger if colors are set once
  setupProjectAnimation();

  for (const target of targetList) {
    if (!target.classList.contains('loaded')) {
      target.classList.add('loaded');
      projectInfo.style.pointerEvents = 'auto';
    }
  }
}

function resetProjectAnimation() {
  targetList.forEach((target) => {
    target.classList.remove('loaded');
  });
  projectInfo.style.pointerEvents = 'none';
}

function setupProjectAnimation() {
  for (const target of targetList) {
    const content = target.innerHTML;
    const color =
      'revealColor' in target.dataset
        ? target.dataset.revealColor
        : getArrayRandomValue(COLOR_LIST);
    target.innerHTML = `<span data-reveal="content"><div data-reveal="cover" style="background-color:${color}"></div><span data-reveal="text">${content}</span></span>`;
  }
  projectInfo.style.pointerEvents = 'auto';
}

// Header animation

export function typeWriter(
  { text, elementSelector, typingDelay, cursorStopDelay },
  i,
  fnCallback
) {
  return new Promise((resolve, reject) => {
    if (i < text.length) {
      document.querySelector(elementSelector).innerHTML =
        text.substring(0, i + 1) +
        '<span class="caret-span" aria-hidden="true"></span>';

      setTimeout(function () {
        resolve(
          typeWriter(
            { text, elementSelector, typingDelay, cursorStopDelay },
            i + 1,
            fnCallback
          )
        );
      }, typingDelay);
    } else {
      setTimeout(function () {
        document.querySelector(elementSelector).innerHTML = text;
        if (typeof fnCallback == 'function') {
          fnCallback();
        }
        resolve();
      }, cursorStopDelay);
    }
  });
}

// Titles animation

export function setupTitlesAnimation() {
  const navTitles = document.querySelectorAll('.nav-titles');

  navTitles.forEach((element) => {
    gsap.fromTo(
      element.querySelectorAll('span'),
      {
        x: 50,
        autoAlpha: 0,
      },
      {
        delay: 0.3,
        duration: 0.8,
        x: 0,
        autoAlpha: 0.9,
        ease: 'power2.easeOut',
        stagger: {
          from: 'start',
          amount: 0.5,
        },
        scrollTrigger: {
          trigger: element,
          start: 'top 100%',
          end: 'bottom 0%',
          scrub: false,
          toggleActions: 'restart reset restart reset',
          markers: false,
          // onEnter: () => gsap.to(element.querySelectorAll('span'), { autoAlpha: 1 }),
          // onLeave: () => gsap.to(element.querySelectorAll('span'), { autoAlpha: 0 }),
          // onEnterBack: () => gsap.to(element.querySelectorAll('span'), { autoAlpha: 0 }),
          // onLeaveBack: () => gsap.to(element.querySelectorAll('span'), { autoAlpha: 0 }),
        },
      }
    );
  });
}

// Arrow animation

export function setupArrowsAnimation() {
  return new Promise((resolve, reject) => {
    const element = document.querySelector('.arrows-container');

    // Initial fade-in animation
    gsap.fromTo(
      element,
      {
        autoAlpha: 0,
      },
      {
        duration: 1,
        autoAlpha: 1,
        ease: 'power2.easeOut',
      }
    );
    // Scroll activated animation
    gsap.fromTo(
      element,
      {
        autoAlpha: 1,
      },
      {
        duration: 0.5,
        autoAlpha: 0,
        ease: 'power2.easeOut',
        scrollTrigger: {
          trigger: element,
          start: 'top 80%',
          end: 'bottom 70%',
          scrub: false,
          toggleActions: 'reverse play reverse none',
          markers: false,
        },
      }
    );

    resolve();
  });
}

// Nav links smooth scroll

export function navLinksScroll() {
  document.querySelectorAll('.nav-links li a[href]').forEach((a) => {
    a.addEventListener('click', (e) => {
      scrollToHash(getSamePageAnchor(a), e);
    });
  });
}

// Detect if a link's href goes to the current page
function getSamePageAnchor(link) {
  if (
    link.protocol !== window.location.protocol ||
    link.host !== window.location.host ||
    link.pathname !== window.location.pathname ||
    link.search !== window.location.search
  ) {
    return false;
  }

  return link.hash;
}

// Scroll to a given hash, preventing the event given if there is one
function scrollToHash(hash, e) {
  const elem = hash ? document.querySelector(hash) : false;
  if (elem) {
    if (e) e.preventDefault();
    gsap.to(window, { scrollTo: elem });
  }
}

// Projects animation

export function projectsScrollObserve(projectsImages) {
  isSmallScreen = window.matchMedia('(max-width: 768px)').matches;

  if (isSmallScreen) {
    projectsImages.forEach((element) => {
      console.log(element);
      gsap.to(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 150%',
          end: 'top 10%',
          scrub: false,
          toggleActions: 'none none none none',
          markers: false,
          onEnter: () => onEnterProjectAnimation(element),
          onLeave: () => resetProjectAnimation(),
          onEnterBack: () => onEnterProjectAnimation(element),
          onLeaveBack: () => resetProjectAnimation(),
        },
      });
    });
  } else {
    projectsImages.forEach((element) => {
      console.log(element);
      gsap.to(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
          end: 'bottom 89%',
          scrub: false,
          toggleActions: 'none none none none',
          markers: false,
          onEnter: () => onEnterProjectAnimation(element),
          onLeave: () => resetProjectAnimation(),
          onEnterBack: () => onEnterProjectAnimation(element),
          onLeaveBack: () => resetProjectAnimation(),
        },
      });
    });
  }
}

function onEnterProjectAnimation(element) {
  populateProjectData(element);
  triggerProjectAnimation();
}
