export const headerText = [
  {
    elementSelector: '.header-1',
    typingDelay: 90,
    cursorStopDelay: 0,
    text: "Hello, I'm ",
  },
  {
    elementSelector: '.header-2',
    typingDelay: 90,
    cursorStopDelay: 300,
    text: "Daniel.",
  },
  {
    elementSelector: '.header-p',
    typingDelay: 15,
    cursorStopDelay: 1500,
    text: "I'm an engineer with a passion for developing everything from full-stack applications to embedded systems. Scroll down to see some of my work.",
  },
];
