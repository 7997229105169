const projectsImages = document.querySelectorAll('.project-images');
const projectInfo = document.querySelector('.project-info');
// import { triggerProjectAnimation, resetProjectAnimation } from './animations';
import { myProjects } from './data/projectsList';
const projectNum = document.querySelector('.project-num');

// let upperOffset = window.innerHeight * 0.4;
// let lowerOffset = window.innerHeight * 0.6;
// let upperOffsetMobile = window.innerHeight * 0.2;
// let lowerOffsetMobile = window.innerHeight * 0.3;

// let observerTrigger, observerReset;

import { projectsScrollObserve } from './animations';

// export function setupProjects() {
//   isSmallScreen = window.matchMedia('(max-width: 768px)').matches;

//   if (observerTrigger) {
//     projectsImages.forEach((p) => {
//       observerTrigger.unobserve(p);
//     });
//   }

//   observerTrigger = new IntersectionObserver(
//     (entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           const projectNumber = entry.target.dataset.projectNumber;
//           const project = myProjects.find((p) => p.id == projectNumber);
//           if (project) {
//             populateProjectData(project);
//             triggerProjectAnimation();

//             // setTimeout(triggerProjectAnimation,  100);
//           }
//         } else resetProjectAnimation();
//       });
//     },
//     {
//       root: null,
//       threshold: 0.0,
//       // rootMargin: isSmallScreen ? `-${upperOffsetMobile}px 0px -${lowerOffsetMobile}px 0px` : `-${upperOffset}px 0px -${lowerOffset}px 0px`,
//       rootMargin: isSmallScreen ? `0px 0px 250px 0px` : `-${upperOffset}px 0px -${lowerOffset}px 0px`,
//     }
//   );

//   observerReset = new IntersectionObserver(
//     (entries) => {
//       entries.forEach((entry) => {
//         if (!entry.isIntersecting) {
//           resetProjectAnimation();
//         }
//       });
//     },
//     {
//       root: null,
//       threshold: 0.0,
//       rootMargin: isSmallScreen ? `0px 0px -100px 0px` : `-${upperOffset}x 0px -${lowerOffset}px 0px`,
//     }
//   );

//   projectsImages.forEach((p) => {
//     observerTrigger.observe(p);
//     observerReset.observe(p);
//   });
// }

export function setupProjects() {
  projectsScrollObserve(projectsImages);
}

export function populateProjectData(projectImages) {
  const projectNumber = projectImages.dataset.projectNumber;
  const project = myProjects.find((p) => p.id == projectNumber);

  projectNum.innerText = project.number;
  projectInfo.querySelector('h2').textContent = project.title;
  projectInfo.querySelector('.project-desc').textContent = project.description;
  projectInfo.querySelector('.project-cat').textContent = project.category;

  const technologies = projectInfo.querySelector('.project-technologies');
  technologies.innerHTML = project.technologies
    .map((tech) => `<span>${tech}</span>`)
    .join(' • ');
  const links = projectInfo.querySelector('.project-links');
  links.innerHTML = project.links
    .map((link) => `<a  href="${link.url}" target="_blank">${link.text}</a>`)
    .join('');
}
