const root = document.documentElement;
const head = document.getElementById('head-animation');
const portrait = document.getElementById('portrait');
const lips = document.getElementById('lips');
let portraitRect = portrait.getBoundingClientRect();
let portraitX = portraitRect.left + portraitRect.width / 2;
let portraitY = portraitRect.top + portraitRect.height / 2;
let width = window.innerWidth;
let height = window.innerHeight;
let debouncedMoveEventHandler = debounce(moveEventHandler, 5);
let animationFrameId;
const headMaxRotation = 7; // Maximum rotation angle in degrees

export function setupPortraitAnimation() {
  // window.addEventListener('resize', () => {
  //   displayWindowSize();
  // });

  displayWindowSize();

  document.addEventListener('mousemove', (e) => {
    let mouseX = e.clientX;
    let mouseY = e.clientY;
    debouncedMoveEventHandler(mouseX, mouseY);
  });

  document.addEventListener('touchmove', (e) => {
    e.preventDefault(); // Prevent scrolling on touch
    const touch = e.touches[0]; // Use the first touch point
    debouncedMoveEventHandler(touch.clientX, touch.clientY);
    // touchMoveHandler(e);
  });
}

export function displayWindowSize() {
  width = window.innerWidth;
  height = window.innerHeight;

  // check if the portrait is visible(it doesnt update portraitRect properly if not)
  if (isElementInViewport(portrait)) {
    updatePortraitVariables();
  } else {
     // Set up an Intersection Observer to wait until the portrait is visible
     let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          updatePortraitVariables();
          observer.disconnect();  
        }
      });
    });

    observer.observe(portrait);
  }
}

function moveEventHandler(mouseX, mouseY) {
  // Cancel the previous animation frame
  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId);
  }

  // Request a new animation frame
  animationFrameId = requestAnimationFrame(() => {
    root.style.setProperty('--mouse-x', mouseX / width);
    root.style.setProperty('--mouse-y', mouseY / height);
    headTilt(headMaxRotation, mouseX, mouseY, portraitX, portraitY);
  });
}

function updatePortraitVariables () {
  portraitRect = portrait.getBoundingClientRect();
  portraitX = portraitRect.left + portraitRect.width / 2;
  portraitY = portraitRect.top + portraitRect.height / 2;
}

function isElementInViewport(el) {
  let rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function headTilt(headMaxRotation, mouseX, mouseY, portraitX, portraitY) {
  const headTiltAngle =
    -headMaxRotation *
    ((mouseX - portraitX + mouseY - portraitY) / (portraitX + portraitY));
  const x = (mouseX - width / 2) / (width / 2);
  const y = (mouseY - height / 2) / (height / 2);
  const tiltX = y * 8;
  const tiltY = x * -7;
  head.style.transform = `rotateX(${tiltX}deg) rotateY(${tiltY}deg) rotate(${headTiltAngle}deg)`;
}

function debounce(func, wait) {
  let timeout;
  return function(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}