export const myProjects = [
    {
      id: 1,
      number: "01",
      title: "LED Controller for Optimized Plant Growth",
      technologies: ["React", "Node.js", "Express", "MongoDB", "Microcontroller", "MQTT", "Linux", "Mosquitto", "Nginx"],
      description: "This project introduces an IoT-based grow light LED controller system, leveraging the ZigBee protocol for efficient wireless communication. Centered around a Raspberry Pi gateway, the system integrates easy to use graphical interface and embedded system based on a microcontroller.",
      links: [
        // { text: "Demo", url: "https://mechmind.com" },
        { text: "Source code", url: "https://github.com/danyw/IoT-LED-Controller" }
      ],
      category: "IoT",
      images: [
        { src: "/assets/Led Controller UI1.png", class: "desktop-image", alt: "LED Controller Picture" },
        { src: "/assets/Led Controller UI2.png", class: "desktop-image", alt: "LED Controller Picture" },
        { src: "/assets/Led Controller.jpg", class: "desktop-image", alt: "LED Controller Picture" },
        { src: "/assets/Led Controller 2.jpg", class: "desktop-image", alt: "LED Controller Picture" },
        { src: "/assets/LED_controller_enclosure1.png", class: "desktop-image", alt: "LED Controller Picture" },      ]
    },
    {
      id: 2,
      number: "02",
      title: "Quantum Spectrometer",
      technologies: ["C", "STM32 Microcontroller", "LVGL", "DMA", "SAR ADC", "SPI" ],
      description: "I designed and built a quantum spectrometer that measures and optimizes light composition within the Photosynthetically Active Radiation (PAR) spectrum. This tool aids plant growers, researchers, and lamp manufacturers by providing detailed light quality metrics to enhance plant growth and chemical composition. The spectrometer features PPFD measurements, exposure time adjustment, and spectral corrections, with capabilities for continuous and peak measurements. Implemented on an STM32 microcontroller, it leverages complex signal generation, ADC conversion, and a user-friendly interface.",
      category: "Embedded Systems",
      links: [
        { text: "", url: "" },
        { text: "Source code", url: "https://github.com/danyw/PAR-Spectrometer" }
      ]
    },
    // {
    //   id: 3,
    //   number: "03",
    //   title: "Project Three",
    //   technologies: ["Java", "Spring Boot", "MySQL"],
    //   description: "This is a description of Project Three.",
    //   category: "Web Development",
    //   links: [
    //     { text: "Demo", url: "https://project-three-demo.com" },
    //     { text: "Source code", url: "https://github.com/user/project-three" }
    //   ]
    // }
  ];